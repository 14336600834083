import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FormContext from '@modules/apply_form/utils/form-context';


export default function Step3() {

  const { workflow } = useContext(FormContext);

  const { t } = useTranslation();

  return (
    <div className="row py-5 my-5">
      <div className="col-sm-12">
        <div className="w-50 mx-auto">
          <h2 className="text-center text-primary">
            {(workflow as any)['title']}
          </h2>
          <h2 className="text-center mt-5 apply-color-text font-weight-light">
            {t('Thanks for signing up!')}
          </h2>
          <p className="text-secondary text-center font-weight-light">
            {t('Your request is currently being registered. In a few minutes, a member of our recruitment team will contact you.')}
          </p>
        </div>
      </div>
    </div>
  );
}

