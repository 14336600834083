import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FormContext from '@modules/apply_form/utils/form-context';

type LandingProps = {
  isExpired: boolean
};

export default function Landing({ isExpired }: LandingProps) {
  const {
    workflow, startProcces, loading, getFieldFromName
  } = useContext(FormContext);
  const { t } = useTranslation();

  return (
    <>
      <section className="background-image-apply">
        <div className="container align-items-center h-100">
          <div className="row  align-items-center h-100">
            <div className="col-lg-8">
              {workflow && (
                <h2 className="mt-3 text-light">
                  {t('HI')}
                  {' '}
                  {(workflow as any)['title']}
                  !
                </h2>
              )}
              <h2 className="mt-3 text-light">
                {t('We have the job of your dreams, where you will grow professionally!')}
              </h2>
              <p className="lead text-light my-4 px-0 col-lg-7">
                {t('In Rootstack you will find a place to grow, share all your ideas, learn new things, and rock with the best international team. We want to see your talent shine and build the future together!')}
              </p>
              {!loading && workflow && (
                <>
                  {isExpired ? (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => window.open('mailto:hr@rootstack.com', '_blank')}
                    >
                      {t('This form has already expired')}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => startProcces!()}
                      className="btn btn-primary"
                    >
                      {t('Start now')}
                    </button>
                  )}
                </>
              )}
              {!loading && !workflow && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => window.open('mailto:hr@rootstack.com', '_blank')}
                >
                  {t('This form is invalid contact hr@rootstack.com')}
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-4">
        <div className="row ">
          <div className="col-lg-6">
            <h5 className="mt-3 text-secundary text-center">
              {t('What do we have for you?')}
            </h5>
            <p className="text-secondary mt-3 mb-5 font-weight-light">
              {t('At Rootstack we care a lot for our collaborators, so we created a package of perks to tell you how important you are to us')}
            </p>
            <h5 className="mt-3 text-secundary text-center">
              {t('Events')}
            </h5>
            <p className="text-secondary mt-3 mb-4 font-weight-light">
              {t('Every month we have recreational activities, where you can share and enjoy a pleasant time with your teammates and make friends around the world.')}
            </p>
            <h5 className="mt-3 text-secundary text-center">
              {t('Stability')}
            </h5>
            <p className="text-secondary mt-3 mb-4 font-weight-light">
              {t('We don’t believe in short-time relationships. The future won’t be built in a couple of months. As we want to see how you shine with all your skills in our projects, we offer you a long-term contract to achieve the most amazing goals together.')}
            </p>
            <div className="row mt-5">
              <div className="col-md-6 mb-4">
                <img className="w-100" src={require('@assets/images/evento_3.png')} alt="" />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12 mb-4">
                    <img className="w-100" src={require('@assets/images/evento_2.png')} alt="" />
                  </div>
                  <div className="col-12">
                    <img className="w-100" src={require('@assets/images/evento_1.png')} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h5 className="mt-3 text-secundary text-center">{t('Growth')}</h5>
            <p className="text-secondary mt-3 mb-4 font-weight-light">
              {t('You will have the opportunity to learn from your team. With tech talks, lunch & learn, courses, and free English lessons. And thats not all, you will be able to share your knowledge with others too. We learn together!')}
            </p>
            <h5 className="mt-3 text-secundary text-center">{t('Private health insurance')}</h5>
            <p className="text-secondary mt-3 mb-4 font-weight-light">
              {t('We care about you and your wellness, so you will have access to private health insurance from the moment you pass the trial period. It’s on us!')}
            </p>
            <h5 className="mt-3 text-secundary text-center">{t('Other benefits')}</h5>
            <ul className="text-secondary font-weight-light">
              <li>{t('Professional development support')}</li>
              <li>{t('Vacations and paid time off')}</li>
              <li>{t('Annual bonus based on performance')}</li>
              <li>{t('Birthday off')}</li>
              <li>{t('Holidays per country')}</li>
              <li>{t('Parental leave')}</li>
            </ul>
            <div className="row">
              <div className="col-md-6 mb-4">
                <img className="w-100" src={require('@assets/images/crecimiento_2.png')} alt="" />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-12 mb-4">
                    <img className="w-100" src={require('@assets/images/crecimiento_1.png')} alt="" />
                  </div>
                  <div className="col-12">
                    <img className="w-100" src={require('@assets/images/crecimiento_3.png')} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <h2 className="text-center text-primary">
          {t('Profits')}
        </h2>
        <p className="text-secondary text-center font-weight-light">
          {t('Competitive salary acording to the market, private health insurance, long-term contract, Joining a growing technology company.')}
        </p>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-3 text-center mb-4">
            <img src={require('@assets/images/beneficios_1.png')} alt="" />
            <div className="apply-benefits mt-3 ">
              <p>
                <strong>+10</strong>
                {' '}
                {t('Years of experience')}
              </p>
            </div>
          </div>
          <div className="col-md-3 text-center mb-4">
            <img src={require('@assets/images/beneficios_2.png')} alt="" />
            <div className="apply-benefits mt-3 ">
              <p>
                <strong>+90</strong>
                {' '}
                {t('Collaborators')}
              </p>
            </div>
          </div>
          <div className="col-md-3 text-center mb-4">
            <img src={require('@assets/images/beneficios_3.png')} alt="" />
            <div className="apply-benefits mt-3 ">
              <p>
                <strong>+9</strong>
                {' '}
                {t('Countries')}
              </p>
            </div>
          </div>
          <div className="col-md-3 text-center mb-4">
            <img src={require('@assets/images/beneficios_4.png')} alt="" />
            <div className="apply-benefits mt-3 ">
              <p>
                <strong>+6</strong>
                {' '}
                {t('Locations')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
