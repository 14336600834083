import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';

import { buildRootnetPhoneFormat, extractRootnetPhoneNumber } from '@core/services/rootnet-service';
import { parseJson } from '@core/helpers/strings';

import FormContext from '@modules/apply_form/utils/form-context';
import allNodeSelectListQuery from '@modules/apply_form/default-query';
import ErrorFormLabel from '@modules/apply_form/components/error-form-label';

import { GenericDataType } from '@models/generic-data-type';
import { File } from '@models/file';

const { fields, currentStepFieldId, cvFileFieldId } = require('@modules/apply_form/config');

export default function Step1() {
  const { t } = useTranslation();
  const { countries } = allNodeSelectListQuery();

  const [phone, setPhone] = useState<string>();
  const [fileState, setFile] = useState<File|null>(null);
  const [fileStateError, setFileError] = useState<boolean|null>(false);

  const errorField = (fieldName: string) => (errors && errors[fieldName] ? 'border-danger' : '');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const {
    submitFirstStep,
    workflowId,
    workflow,
    loading,
    getFieldFromName,
    getFieldCollectionByName,
    uploadFile
  } = React.useContext(FormContext);

  useEffect(() => {
    if (workflow) {
      const phoneNumber = extractRootnetPhoneNumber(countries, getFieldFromName!('phone number')?.value);
      setPhone(phoneNumber);
    }
  }, [workflow]);

  // Validate cv file size.
  const handleCVInput = (event: any) => {
    const file = event?.target?.files?.[0] as unknown as File;
    setFileError(false);
    if (file.size > 10000000) {
      setFile(null);
      setFileError(true);
    } else {
      setFile(file);
    }
  };
  const onSubmit = async (data: object) => {
    const workflowMetas = [];
    Object.keys(data).map((item) => {
      if (item && item !== `${fields.CV}` && item !== `${fields['phone number']}`) {
        workflowMetas.push({
          workflow_type_field_id: parseInt(item, 10),
          value: JSON.stringify(data[item]),
        });
      }

      return null;
    });

    if (fileState) {
      const file = await uploadFile!(fileState) as File;

      workflowMetas.push({
        workflow_type_field_id: cvFileFieldId,
        value: JSON.stringify({ managedFile: file?.fileId }),
      });
    }

    workflowMetas.push(
      {
        workflow_type_field_id: currentStepFieldId,
        value: 1,
      },
      {
        workflow_type_field_id: fields['phone number'],
        value: JSON.stringify(buildRootnetPhoneFormat(countries, phone || ''))
      }
    );

    const parsedData = {
      workflow_id: parseInt(workflowId, 10),
      workflow_metas: workflowMetas.filter((meta) => !!meta.workflow_type_field_id),
    };

    submitFirstStep!(JSON.stringify(parsedData));
  };

  if (!workflow) {
    return <></>;
  }

  return (
    <div className="row">
      <div className="col-sm-8">
        <div className="mt-4">
          <h2 className="text-center text-primary">
            {t('HI')}
            {' '}
            {parseJson(getFieldFromName!('First name')?.value)}
            !
          </h2>
          <h2 className="text-center  apply-color-text font-weight-light">
            {t('Welcome you can apply now!')}
          </h2>
          <p className="text-secondary text-center font-weight-light mt-4">
            {t('Fill out the following form to start your job application in the company, share your skills and experience so that we can learn a little more about you. With more than 10 years of foundation and over 300 international clients, Rootstack is one of the technology companies with the highest growth projection in Latin America.')}
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-4">
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">{t('Name')}</span>
                <input
                  disabled
                  value={
                    parseJson(getFieldFromName!('first name')?.value)
                  }
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">{t('Last name')}</span>
                <input
                  value={
                    parseJson(getFieldFromName!('last name')?.value)
                  }
                  disabled
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">{t('E-mail')}</span>
                <input
                  disabled
                  value={
                    parseJson(getFieldFromName!('email')?.value)
                  }
                  className="form-control"
                />
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">
                  {t('Location')}
                  <span className="text-danger"> *</span>
                </span>
                <select
                  {...register(
                    getFieldCollectionByName!('Location').id.toString(),
                    {
                      required: true
                    }
                  )}
                  className="form-control"
                  defaultValue={parseJson(getFieldFromName!('Location')?.value)}
                >
                  <option value="">{t('Select an option')}</option>
                  {countries.map(({ node }) => (
                    <option
                      key={`${node?.crmId}`}
                      value={`${node?.crmId}`}
                    >
                      {node?.name}
                    </option>
                  ))}
                </select>
                <ErrorFormLabel
                  error={errors[getFieldCollectionByName!('Location').id.toString()]}
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">
                  {t('Phone')}
                  <span className="text-danger"> *</span>
                </span>
                <PhoneInput
                  value={phone}
                  className="form-control"
                  {
                    ...register(
                      getFieldCollectionByName!('Phone number').id.toString(),
                      { required: true, value: phone }
                    )
                  }
                  onChange={(value) => setPhone(value)}
                />
                <ErrorFormLabel
                  error={errors[getFieldCollectionByName!('Phone number').id.toString()]}
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">
                  {t('Current salary')}
                  <span className="text-danger"> *</span>
                </span>
                <input
                  {...register(
                    getFieldCollectionByName!(
                      'Current salary range'
                    ).id.toString(),
                    { required: true }
                  )}
                  defaultValue={
                    getFieldFromName!('Current salary range')?.displayValue
                  }
                  type="number"
                  className="form-control"
                />
                <ErrorFormLabel
                  error={errors[getFieldCollectionByName!('Current salary range').id.toString()]}
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">
                  {t('Wage aspiration')}
                  <span className="text-danger"> *</span>
                </span>
                <input
                  {...register(
                    getFieldCollectionByName!(
                      'Salary expectation'
                    ).id.toString(),
                    { required: true }
                  )}
                  defaultValue={
                    getFieldFromName!('Salary expectation')?.displayValue
                  }
                  type="number"
                  className="form-control"
                />
                <ErrorFormLabel
                  error={errors[getFieldCollectionByName!('Salary expectation').id.toString()]}
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">{t('Have you a Linkedin link?')}</span>
                <input
                  {...register(
                    getFieldCollectionByName!('Linkedin').id.toString(),
                    { required: false }
                  )}
                  defaultValue={getFieldFromName!('Linkedin')?.displayValue}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <span className="apply-color-text font-weight-bold">
                  {t('Are you comfortable interviewing and working in english?')}
                  <span className="text-danger">*</span>
                </span>
                <select
                  {...register(
                    getFieldCollectionByName!(
                      'Can you have a video-call conversation in english?'
                    ).id.toString(),
                    { required: true }
                  )}
                  defaultValue={
                    parseJson(
                      getFieldFromName!(
                        'Can you have a video-call conversation in english?'
                      )?.value
                    )
                  }
                  className="form-control"
                >
                  <option value="">{t('Select an option')}</option>
                  <option value="true">{t('yes')}</option>
                  <option value="">{t('no')}</option>
                </select>
                <ErrorFormLabel
                  error={errors[getFieldCollectionByName!('Can you have a video-call conversation in english?').id.toString()]}
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <span className="apply-color-text font-weight-bold">
                CV
                <span className="text-danger"> *</span>
              </span>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFileLangHTML"
                  {...register(`${fields.CV}`, {
                    required: true,
                  })}
                  accept="application/pdf"
                  onChange={(event) => handleCVInput(event)}
                />
                <label className={`custom-file-label ${errorField(fields.CV)}`} htmlFor="customFileLangHTML" data-browse={t('Browse')}>
                  {fileState?.name || t('Select a file')}
                </label>
                { fileStateError ? <span className="error text-danger">{`${t('File max size')}: 10MB`}</span> : <></> }
                <ErrorFieldAlert errors={errors} fieldName={fields.CV} />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="offset-md-9 col-md-3 d-flex justify-content-center">
              <button disabled={loading} className="btn btn-primary btn-block" type="submit">
                {t('Next')}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-sm-1" />
      <div className="col-sm-3">
        <div className="mt-5 mb-5">
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <div className="text-step1-apply mt-3 ">
                <h4 className="text-step1-apply">
                  {t('About')}
                  <br />
                  <strong>Rootstack</strong>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/beneficios_1.png')} alt="" />
              <div className="text-step1-apply mt-3 ">
                <p>
                  <strong>+10</strong>
                  {' '}
                  {t('Years of experience')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/beneficios_2.png')} alt="" />
              <div className="text-step1-apply mt-3 ">
                <p>
                  <strong>+90</strong>
                  {' '}
                  {t('Collaborators')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/beneficios_3.png')} alt="" />
              <div className="text-step1-apply mt-3 ">
                <p>
                  <strong>+9</strong>
                  {' '}
                  {t('Countries')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/beneficios_4.png')} alt="" />
              <div className="text-step1-apply mt-3 ">
                <p>
                  <strong>+6</strong>
                  {' '}
                  {t('Locations')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center d-flex justify-content-center flex-row  align-items-center">
              <img src={require('@assets/images/step_1.png')} className="image-step1-apply" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ErrorFieldAlert({ errors, fieldName }: { errors: GenericDataType, fieldName: string }) {
  const { t } = useTranslation();

  return (errors && errors[fieldName]
    ? <span className="error text-danger">{t('Required field')}</span>
    : null);
}
