// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Form from '@shared/webform/form';

import { parseJson } from '@core/helpers/strings';

import FormContext from '@modules/apply_form/utils/form-context';
import SkillControl from '@modules/apply_form/forms/controls/skill-control';
import ErrorFormLabel from '@modules/apply_form/components/error-form-label';
import allNodeSelectListQuery from '@modules/apply_form/default-query';

import { GenericDataType } from '@models/generic-data-type';
import { RootnetItem } from '@models/rootnet';

const {
  currentStepFieldId,
  areasWithSkills,
} = require('@modules/apply_form/config');

export default function Step2() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { form } = allNodeSelectListQuery();
  const { t } = useTranslation();

  const {
    submitStep,
    workflowId,
    workflow,
    loading,
    getFieldCollectionByName,
    getListOptionFromField,
    getFieldFromName,
  } = useContext(FormContext);
  let primaryRole = useContext(FormContext)
  primaryRole = 'Development';
  const [frontendOptions, setFrontendOptions] = useState<GenericDataType[]>([]);
  const [backendOptions, setBackendOptions] = useState<GenericDataType[]>([]);
  const [cmsOptions, setCMSOptions] = useState<GenericDataType[]>([]);
  const [mobileOptions, setMobileOptions] = useState<GenericDataType[]>([]);
  const [languajesOptions, setLanguajesOptions] = useState<GenericDataType[]>([]);
  const [platformOptions, setPlatformOptions] = useState<GenericDataType[]>([]);
  const [toolsOptions, setToolsOptions] = useState<GenericDataType[]>([]);
  const [pmOptions, setPmOptions] = useState<GenericDataType[]>([]);
  const [devopsOptions, setDevopsOptions] = useState<GenericDataType[]>([]);
  const [skills, setSkills] = useState<GenericDataType>({});

  useEffect(() => {
    if (!workflow || !primaryRole) {
      return;
    }

    const frontend = getFieldCollectionByName('Skills Frontend');
    const backend = getFieldCollectionByName('Skills backend');
    const cms = getFieldCollectionByName('Skills CMS');
    const mobile = getFieldCollectionByName('Skills mobile');
    const languajes = getFieldCollectionByName('Skills Lenguajes');
    const platform = getFieldCollectionByName('Skills platforms');
    const tools = getFieldCollectionByName('Skills tools');
    const devops = getFieldCollectionByName('Skills Devops');
    const pm = getFieldCollectionByName('Skills PM');

    (async () => {
      const [
        reqFrontend,
        reqBackend,
        reqCMS,
        reqMobile,
        reqLanguajes,
        reqPlatform,
        reqTools,
        reqPm,
        reqDevops
      ] = await Promise.all([
        getListOptionFromField(
          parseJson(frontend?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(backend?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(cms?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(mobile?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(languajes?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(platform?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(tools?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(pm?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(devops?.field_config || '').subFields[0].configuration.list_info.id
        )
      ]);

      setFrontendOptions(
        reqFrontend?.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setBackendOptions(
        reqBackend.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setCMSOptions(
        reqCMS.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setMobileOptions(
        reqMobile.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setLanguajesOptions(
        reqLanguajes.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setPlatformOptions(
        reqPlatform.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setToolsOptions(
        reqTools.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setDevopsOptions(
        reqDevops.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setPmOptions(
        reqPm.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );
    })();
  }, [workflow, primaryRole]);

  const handleExpertise = (
    ownerName: string,
    position: string,
    value: string,
    itemCero: string,
    index: number
  ) => {
    if (skills[ownerName]) {
      setSkills({ ...skills, [ownerName]: { ...skills[ownerName], [index]: { ...skills[ownerName][index], [position]: value, '0': itemCero } } });
    } else {
      setSkills({ ...skills, [ownerName]: { ...skills[ownerName], [index]: { [position]: value, '0': itemCero } } });
    }
  };

  const onSubmit = async (data: { [key: string]: string }) => {
    const workflowMetas = [];

    Object.keys(data).map((item) => {
      if (item !== getFieldCollectionByName('CV')?.id.toString()) {
        workflowMetas.push({
          workflow_type_field_id: parseInt(item, 10),
          value: JSON.stringify(data[item]),
        });
      }

      return null;
    });

    Object.keys(skills).forEach((item) => {
      workflowMetas.push({
        workflow_type_field_id: item,
        value: JSON.stringify(Object.keys(skills[item]).map((it, i) => ({
          ...skills[item][it],
          can_delete_row: true,
          can_edit_row: {
            '0': true,
            '1': true,
            '2': true,
            '3': true,
            _index: 0,
            _rowIndex: i
          }
        }))),
      });
    });

    workflowMetas.push({
      workflow_type_field_id: currentStepFieldId,
      value: 2,
    });

    const parsedData = {
      workflow_id: parseInt(workflowId, 10),
      workflow_metas: workflowMetas.filter((meta) => !!meta.workflow_type_field_id),
    };

    submitStep(JSON.stringify(parsedData), 2);
    window.scrollTo(0, 0);
  };

  if (loading || !workflow || !primaryRole) {
    return <></>;
  }

  return (
    <div className="row mt-4">
      <div className="col-md-8">
        <div>
          <h2 className="text-center text-primary">
            {t('PROFESSIONAL PROFILE')}
          </h2>
          <p className="text-secondary text-center font-weight-light mt-4">
            {t(
              'In the following form, explain why you should be part of our team of developers. Tell us about your achievements in the professional field and in which technologies you consider yourself an expert.'
            )}
          </p>
        </div>
        <Form
          title={form.title || ''}
          description={form.subtitle || ''}
          webform={form as Webform}
          additionalClasses=""
          stepper={2}
          formFields={true}
        />
      </div>
      <div className="col-md-1" />
      <div className="col-md-3">
        <div className="sticky-top container-step2-apply py-4 mt-4">
          <div className="text-step1-apply">
            <h4 className="text-step1-apply text-white text-center mb-4">
              {t('About')}
              <br />
              <strong>Rootstack</strong>
            </h4>
            <div className="card bg-transparent m-3">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="embed-responsive-item"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/kC2gBAd5UmQ"
                />
              </div>
              <div className="card-body p-0 mt-4">
                <p className="card-text text-white">
                  {t(
                    'With more than 10 years of experience, Rootstack is one of the technology companies with the highest growth projection in Latin America. With over 300 projects completed, our team of experts is ready to begin creating the technology experiences of today and tomorrow.'
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
