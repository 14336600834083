import React from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '@core/helpers/strings';

import { QuestionsType } from '@modules/apply_form/utils/interfaces';

type FormControlProps = {
    fieldCollectionId: string;
    question: QuestionsType;
    register: Function;
    setValue: Function;
};

export default function FormControl({
  fieldCollectionId,
  question,
  register,
  setValue
}: FormControlProps) {
  const { t } = useTranslation();

  const optionsList = question.options.map((item) => (
    <option
      key={`${item.value}`}
      value={`${item.value}`}
    >
      {capitalizeFirstLetter(item.name)}
    </option>
  ));

  switch (question.fieldType) {
    case 'select':
      return (
        <div className="form-group d-flex flex-column justify-content-between h-100 py-4">
          <div>
            {t(question.question)}
          </div>
          <select
            {...register(
              fieldCollectionId,
              {
                required: true
              }
            )}
            className="form-control"
            onChange={(event) => setValue(fieldCollectionId, event.target.value)}
          >
            <option value="">{t('Select an option')}</option>
            {optionsList}
          </select>
        </div>
      );
    case 'textarea':
      return (
        <div className="form-group">
          <div>
            {t(question.question)}
          </div>
          <textarea
            className="form-control"
            {...register(
              fieldCollectionId,
              {
                required: true,
                maxLength: 500,
              }
            )}
            rows={3}
          />
          <small
            id={fieldCollectionId}
            className="form-text text-muted text-right"
          >
            {t('(maximum 500 characters)')}
          </small>
        </div>
      );
    default:
      return (
        <div className="form-group">
          <div>
            {t(question.question)}
          </div>
          <input
            {...register(
              fieldCollectionId,
              {
                required: true,
                maxLength: 500,
              }
            )}
            type="text"
            id={fieldCollectionId}
            className="form-control"
          />
        </div>
      );
  }
}
