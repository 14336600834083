import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';
import moment from 'moment';

import decode from '@core/services/jwt-service';
import Footer from '@core/layout/footer/footer';

import UseForm from './utils/use-form';
import ApplicationForm from './forms/application-form';

import './styles.scss';

export default function ApplicationFormLayout() {
  const { t, i18n } = useTranslation();
  const [expireDays, setExpireDays] = useState<number>();
  const [isExpired, setIsExpired] = useState<boolean>();

  useEffect(() => {
    let days = -1;

    const urlParams = new URLSearchParams(window.location.search);
    const decoded = decode(urlParams.get('workflow') || '');

    const now = moment(decoded.expiresAt);
    const end = moment(new Date());
    const duration = moment.duration(now.diff(end));

    days = duration.asDays();

    const expiredAt = (Math.floor(days) + 1) ?? 0;
    setExpireDays(expiredAt);

    setIsExpired(expiredAt <= 0);
  }, []);

  return (
    <UseForm>
      <nav className="navbar background-apply-nav navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <GatsbyLink className="navbar-brand" to={`/${i18n.language}`}>
            <img
              src={require('@assets/images/logo_white.png')}
              width="180"
              className="d-inline-block align-top"
              alt=""
            />
          </GatsbyLink>
          {!isExpired && expireDays && (
            <span className="navbar-text text-light">
              {`${t('Form expires in ')} ${expireDays} ${t('days')}`}
            </span>
          )}
        </div>
      </nav>
      <ApplicationForm isExpired={!!isExpired} />
      <Footer />
    </UseForm>
  );
}
