import React, { useContext, useEffect, useState } from 'react';

import FormContext from '@modules/apply_form/utils/form-context';
import { QuestionsType } from '@modules/apply_form/utils/interfaces';
import FormControl from '@modules/apply_form/forms/controls/form-control';

const { questions } = require('@modules/apply_form/config');

type QuestionsProps = {
  register: Function;
  setValue: Function;
  area: string;
};

type QuestionProps = {
  question: QuestionsType;
  register: Function;
  setValue: Function;
};

export default function Questions({ register, setValue, area = '' }: QuestionsProps) {
  if (!area) {
    return <></>;
  }

  const questionsByArea = questions[area.toLowerCase()] || [];

  return (
    <div className="row">
      {
        questionsByArea.map((question: QuestionsType) => (
          <div key={question.question} className="col-md-6">
            <Question
              question={question}
              register={register}
              setValue={setValue}
            />
          </div>
        ))
      }
    </div>
  );
}

function Question({ question, register, setValue }: QuestionProps) {
  const {
    getFieldCollectionByName,
    getListOptionFromField
  } = useContext(FormContext);

  const [options, setOptions] = useState<{ value: string, name: string }[]>([{
    value: 'true',
    name: 'yes',
  }, {
    value: 'false',
    name: 'no',
  }]);

  const fieldCollectionId
    = getFieldCollectionByName!(
      question.fieldCollectionName
    ).id.toString();

  if (!fieldCollectionId) {
    return <></>;
  }

  useEffect(() => {
    (async () => {
      if (question.findOptions) {
        const optionsByCollectionName = getFieldCollectionByName!(question.fieldCollectionName);
        const req = await getListOptionFromField!(
          JSON.parse(optionsByCollectionName.field_config).list_info.id
        );

        setOptions(
          req.items[0].elements.map(
            (item) => ({ value: `${item.id}`, name: item.name })
          )
        );
      }
    })();
  }, []);

  return (
    <FormControl
      question={{
        ...question,
        options
      }}
      fieldCollectionId={fieldCollectionId}
      register={register}
      setValue={setValue}
    />
  );
}
