import React from 'react';
import { useTranslation } from 'react-i18next';

import { GenericDataType } from '@models/generic-data-type';

type SkillsControlProps = {
  controlKey: number;
  skillName: string;
  skills: GenericDataType[];
  skill: GenericDataType;
  handleExpertise: Function;
  getFieldFromName: Function;
};

export default function SkillControl({
  controlKey, skillName, skills, skill, handleExpertise, getFieldFromName
}: SkillsControlProps) {
  const { t } = useTranslation();

  return (
    <>
      <div key={skill.value} className="mt-2">
        <div className="row">
          <div className="col-md-2 d-flex align-items-center">
            <h5 className="mt-3 font-weight-light">{skills[skills.findIndex((it) => it?.value.toString() === skill['0'].toString())].label}</h5>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <div>
                {t('Expertise')}
              </div>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                onChange={(e) => handleExpertise(getFieldFromName(skillName).workflow_type_field_id, 2, e.target.value, skill['0'], controlKey)}
              >
                <option value="">{t('Select an option')}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              <span className="small">
                {t('With 5 being expert, how do you grade yourself')}
              </span>
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <div>{t('Number of projects')}</div>
              <input
                type="number"
                defaultValue={0}
                min={0}
                onChange={(e) => handleExpertise(getFieldFromName(skillName).workflow_type_field_id, 1, e.target.value, skill['0'], controlKey)}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="offset-md-2 col-md-10">
            <div className="form-group">
              <div>
                {t('Experience')}
              </div>
              <textarea
                onChange={(e) => handleExpertise(getFieldFromName(skillName).workflow_type_field_id, 3, e.target.value, skill['0'], controlKey)}
                className="form-control"
              />
              <span className="small mb-0">
                {t('Please describe what you\'ve done with this skill')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
