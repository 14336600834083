import React, { useContext } from 'react';
import { Step, Stepper } from 'react-form-stepper';
import { useTranslation } from 'react-i18next';

import FormContext from '@modules/apply_form/utils/form-context';
import Landing from '@modules/apply_form/landing';

import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';

const connectorStyleProps = {
  disabledColor: '#C4C4C4',
  activeColor: '#FA8F04',
  completedColor: '#FA8F04',
  stepSize: '2em',
  size: 5,
  style: 'solid'
};

const stepStyleProps = {
  activeBgColor: '#FA8F04',
  activeTextColor: '#FA8F04',
  completedBgColor: '#FA8F04',
  completedTextColor: '#FA8F04',
  inactiveBgColor: '#C4C4C4',
  inactiveTextColor: '#C4C4C4',
  size: '2em',
  circleFontSize: '1rem',
  labelFontSize: '0.875rem',
  borderRadius: '50%',
  fontWeight: 500,
};

type ApplicationFormProps = {
  isExpired: boolean
};

export default function ApplicationForm({ isExpired }: ApplicationFormProps) {
  const { t } = useTranslation();
  const { currentStep, startedForm } = useContext(FormContext);

  return (
    <>
      {!startedForm || isExpired ? (
        <Landing isExpired={isExpired} />
      ) : (
        <>
          <div className="container mb-5">
            <Stepper
              activeStep={currentStep}
              connectorStyleConfig={connectorStyleProps}
              styleConfig={stepStyleProps}
            >
              <Step label={t('General questions')}>
                <div className="apply-step-dot" />
              </Step>
              <Step label={t('We want to meet you')}>
                <div className="apply-step-dot" />
              </Step>
              <Step label={t('Done')}>
                <div className="apply-step-dot" />
              </Step>
            </Stepper>
            <StepComponent currentStep={currentStep} />
          </div>
        </>
      )}
    </>
  );
}

function StepComponent({ currentStep }: { currentStep: number }) {
  switch (currentStep) {
    case 1:
      return <Step2 />;
    case 2:
      return <Step3 />;
    default:
      return <Step1 />;
  }
}
