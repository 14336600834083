import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormContext from '@modules/apply_form/utils/form-context';
import Questions from '@modules/apply_form/forms/questions';

const {
  currentStepFieldId,
  generalQuestionsField,
  questions
} = require('@modules/apply_form/config');

export default function Step2() {
  const {
    register,
    handleSubmit,
    setValue
  } = useForm();

  const { t } = useTranslation();

  const {
    submitStep,
    workflowId,
    workflow,
    primaryRole,
    loading,
    getFieldCollectionByName,
  } = useContext(FormContext);

  const onSubmit = async (data: object) => {
    const workflowMetas = [];
    let generalQuestions = '';

    Object.keys(questions).forEach((key) => {
      questions[key]
        .forEach(
          (item: { question: string, fieldCollectionName: string }) => {
            const { question, fieldCollectionName } = item;

            const fieldCollection = getFieldCollectionByName!(fieldCollectionName);
            const fieldCollectionId = fieldCollection.id.toString();

            if (data[fieldCollectionId]) {
              generalQuestions = generalQuestions
                ? `${generalQuestions}Question: ${question}, Answer: ${data[fieldCollectionId]}\n`
                : `Question: ${question}, Answer: ${data[fieldCollectionId]}\n`;
            }
          }
        );
    });

    if (generalQuestions) {
      workflowMetas.push({
        workflow_type_field_id: generalQuestionsField,
        value: JSON.stringify(generalQuestions),
      });
    }

    Object.keys(data).map((item) => {
      if (item !== getFieldCollectionByName!('CV').id.toString()) {
        workflowMetas.push({
          workflow_type_field_id: parseInt(item, 10),
          value: JSON.stringify(data[item]),
        });
      }

      return null;
    });

    workflowMetas.push({
      workflow_type_field_id: currentStepFieldId,
      value: 2,
    });

    const parsedData = {
      workflow_id: parseInt(workflowId, 10),
      workflow_metas: workflowMetas.filter((meta) => !!meta.workflow_type_field_id),
    };

    submitStep!(JSON.stringify(parsedData), 2);
    window.scrollTo(0, 0);
  };

  if (loading || !workflow || !primaryRole) {
    return <></>;
  }

  return (
    <div className="row mt-4">
      <div className="col-md-12">
        <div>
          <h2 className="text-center text-primary">{t('PROFESSIONAL PROFILE')}</h2>
        </div>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          {primaryRole && (
            <>
              <h4
                className="apply-color-text my-4 font-weight-bold text-center"
              >
                {`${t('Questions by role')}: ${t(primaryRole)}`}
              </h4>
              <Questions register={register} setValue={setValue} area={primaryRole} />
            </>
          )}
          <div className="row my-4">
            <div className="offset-md-9 col-md-3 d-flex justify-content-center">
              <button disabled={loading} className="btn btn-primary btn-block" type="submit">
                {t('Next')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
