// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { parseJson } from '@core/helpers/strings';

import FormContext from '@modules/apply_form/utils/form-context';
import SkillControl from '@modules/apply_form/forms/controls/skill-control';
import ErrorFormLabel from '@modules/apply_form/components/error-form-label';

import { GenericDataType } from '@models/generic-data-type';
import { RootnetItem } from '@models/rootnet';

const {
  currentStepFieldId,
  generalQuestionsField,
  areasWithSkills,
  questions
} = require('@modules/apply_form/config');

export default function Step3() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const {
    submitStep,
    workflowId,
    workflow,
    primaryRole,
    loading,
    getFieldCollectionByName,
    getListOptionFromField,
    getFieldFromName,
  } = useContext(FormContext);

  const [frontendOptions, setFrontendOptions] = useState<GenericDataType[]>([]);
  const [backendOptions, setBackendOptions] = useState<GenericDataType[]>([]);
  const [cmsOptions, setCMSOptions] = useState<GenericDataType[]>([]);
  const [mobileOptions, setMobileOptions] = useState<GenericDataType[]>([]);
  const [languajesOptions, setLanguajesOptions] = useState<GenericDataType[]>([]);
  const [platformOptions, setPlatformOptions] = useState<GenericDataType[]>([]);
  const [toolsOptions, setToolsOptions] = useState<GenericDataType[]>([]);
  const [pmOptions, setPmOptions] = useState<GenericDataType[]>([]);
  const [devopsOptions, setDevopsOptions] = useState<GenericDataType[]>([]);
  const [skills, setSkills] = useState<GenericDataType>({});

  useEffect(() => {
    if (!workflow || !primaryRole) {
      return;
    }

    const frontend = getFieldCollectionByName('Skills Frontend');
    const backend = getFieldCollectionByName('Skills backend');
    const cms = getFieldCollectionByName('Skills CMS');
    const mobile = getFieldCollectionByName('Skills mobile');
    const languajes = getFieldCollectionByName('Skills Lenguajes');
    const platform = getFieldCollectionByName('Skills platforms');
    const tools = getFieldCollectionByName('Skills tools');
    const devops = getFieldCollectionByName('Skills Devops');
    const pm = getFieldCollectionByName('Skills PM');

    (async () => {
      const [
        reqFrontend,
        reqBackend,
        reqCMS,
        reqMobile,
        reqLanguajes,
        reqPlatform,
        reqTools,
        reqPm,
        reqDevops
      ] = await Promise.all([
        getListOptionFromField(
          parseJson(frontend?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(backend?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(cms?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(mobile?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(languajes?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(platform?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(tools?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(pm?.field_config || '').subFields[0].configuration.list_info.id
        ),
        getListOptionFromField(
          parseJson(devops?.field_config || '').subFields[0].configuration.list_info.id
        )
      ]);

      setFrontendOptions(
        reqFrontend?.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setBackendOptions(
        reqBackend.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setCMSOptions(
        reqCMS.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setMobileOptions(
        reqMobile.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setLanguajesOptions(
        reqLanguajes.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setPlatformOptions(
        reqPlatform.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setToolsOptions(
        reqTools.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setDevopsOptions(
        reqDevops.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setPmOptions(
        reqPm.items[0].elements.map((item: RootnetItem) => ({
          value: item.id,
          label: item.name,
        }))
      );
    })();
  }, [workflow, primaryRole]);

  const handleExpertise = (
    ownerName: string,
    position: string,
    value: string,
    itemCero: string,
    index: number
  ) => {
    if (skills[ownerName]) {
      setSkills({ ...skills, [ownerName]: { ...skills[ownerName], [index]: { ...skills[ownerName][index], [position]: value, '0': itemCero } } });
    } else {
      setSkills({ ...skills, [ownerName]: { ...skills[ownerName], [index]: { [position]: value, '0': itemCero } } });
    }
  };

  const onSubmit = async (data: { [key: string]: string }) => {
    const workflowMetas = [];
    let generalQuestions = '';

    Object.keys(questions).forEach((key) => {
      questions[key]
        .forEach(
          (item: { question: string, fieldCollectionName: string }) => {
            const fieldCollection = getFieldCollectionByName(item.fieldCollectionName);
            const fieldCollectionId = fieldCollection?.id.toString();

            if (fieldCollectionId && data[fieldCollectionId]) {
              generalQuestions = (
                generalQuestions
                  ? `${generalQuestions}Question: ${item.question}, Answer: ${data[fieldCollectionId]}\n`
                  : `Question: ${item.question}, Answer: ${data[fieldCollectionId]}\n`
              );
            }
          }
        );
    });

    if (generalQuestions) {
      workflowMetas.push({
        workflow_type_field_id: generalQuestionsField,
        value: JSON.stringify(generalQuestions),
      });
    }

    Object.keys(data).map((item) => {
      if (item !== getFieldCollectionByName('CV')?.id.toString()) {
        workflowMetas.push({
          workflow_type_field_id: parseInt(item, 10),
          value: JSON.stringify(data[item]),
        });
      }

      return null;
    });

    Object.keys(skills).forEach((item) => {
      workflowMetas.push({
        workflow_type_field_id: item,
        value: JSON.stringify(Object.keys(skills[item]).map((it, i) => ({
          ...skills[item][it],
          can_delete_row: true,
          can_edit_row: {
            '0': true,
            '1': true,
            '2': true,
            '3': true,
            _index: 0,
            _rowIndex: i
          }
        }))),
      });
    });

    workflowMetas.push({
      workflow_type_field_id: currentStepFieldId,
      value: 3,
    });

    const parsedData = {
      workflow_id: parseInt(workflowId, 10),
      workflow_metas: workflowMetas.filter((meta) => !!meta.workflow_type_field_id),
    };

    submitStep(JSON.stringify(parsedData), 3);
    window.scrollTo(0, 0);
  };

  if (loading || !workflow || !primaryRole) {
    return <></>;
  }

  return (
    <div className="row mt-4">
      <div className="col-md-8">
        <div>
          <h2 className="text-center text-primary">{t('PROFESSIONAL PROFILE')}</h2>
          <p className="text-secondary text-center font-weight-light mt-4">
            {t('In the following form, explain why you should be part of our team of developers. Tell us about your achievements in the professional field and in which technologies you consider yourself an expert.')}
          </p>
        </div>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="row mt-4">
            <div className="col-12">
              <h4 className="apply-color-text font-weight-bold mt-4 text-center">
                {t('General questions')}
              </h4>
            </div>

            {questions.general.map((question: GenericDataType) => (
              <div key={question.question} className="col-md-12">
                <div className="form-group">
                  <div className="mt-3 font-weight-light">
                    {t(question.question)}
                    <span className="text-danger"> *</span>
                  </div>
                  <textarea
                    className="form-control"
                    {...register(
                      getFieldCollectionByName(question.fieldCollectionName)?.id.toString() || '',
                      {
                        required: true,
                        maxLength: 500,
                      }
                    )}
                    rows={3}
                  />
                  <ErrorFormLabel
                    error={errors[
                      getFieldCollectionByName(question.fieldCollectionName)?.id.toString() || ''
                    ]}
                    message={t('This field must be filled correctly')}
                  />
                  <small
                    className="form-text text-muted text-right"
                  >
                    {t('(maximum 500 characters)')}
                  </small>
                </div>
              </div>
            ))}

            <div className="col-md-12">
              <div className="form-group">
                <div className="mt-3 font-weight-light">
                  {t('Internet speed')}
                  <span className="text-danger"> *</span>
                </div>
                <input
                  className="form-control"
                  placeholder="512MB, 1GB, 10GB, 100GB"
                  {...register(
                    getFieldCollectionByName('Internet speed').id.toString(),
                    {
                      required: true,
                      maxLength: 500,
                    }
                  )}
                />
                <ErrorFormLabel
                  error={errors[getFieldCollectionByName('Internet speed').id.toString()]}
                  message={t('This field must be filled correctly')}
                />
              </div>
            </div>
          </div>

          {areasWithSkills.includes(primaryRole)
            ? (
              <>
                <div className="row my-2">
                  <div className="col-12">
                    <h4 className="apply-color-text font-weight-bold text-center mt-4">
                      {t('My skills')}
                    </h4>
                  </div>
                </div>

                {frontendOptions.length > 0 && (parseJson(getFieldFromName('Skills Frontend')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills Frontend"
                      skills={frontendOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}

                {backendOptions.length > 0 && (parseJson(getFieldFromName('Skills backend')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills backend"
                      skills={backendOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}

                {mobileOptions.length > 0 && (parseJson(getFieldFromName('Skills mobile')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills mobile"
                      skills={mobileOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}

                {cmsOptions.length > 0 && (parseJson(getFieldFromName('Skills CMS')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills CMS"
                      skills={cmsOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}

                {languajesOptions.length > 0 && (parseJson(getFieldFromName('Skills Lenguajes')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills Lenguajes"
                      skills={languajesOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}

                {platformOptions.length > 0 && (parseJson(getFieldFromName('Skills platforms')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills platforms"
                      skills={platformOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}

                {toolsOptions.length > 0 && (parseJson(getFieldFromName('Skills tools')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills tools"
                      skills={toolsOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}

                {devopsOptions.length > 0 && (parseJson(getFieldFromName('Skills Devops')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills Devops"
                      skills={devopsOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}

                {pmOptions.length > 0 && (parseJson(getFieldFromName('Skills PM')?.value) || [])
                  .map((item: GenericDataType, i: number) => (
                    <SkillControl
                      key={item[0]}
                      controlKey={i}
                      skillName="Skills PM"
                      skills={pmOptions}
                      skill={item}
                      handleExpertise={handleExpertise}
                      getFieldFromName={getFieldFromName}
                    />
                  ))}
              </>
            ) : null}

          <div className="row my-4">
            <div className="offset-md-9 col-md-3 d-flex justify-content-center">
              <button disabled={loading} className="btn btn-primary btn-block" type="submit">
                {t('Next')}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-md-1" />
      <div className="col-md-3">
        <div className="sticky-top container-step2-apply py-4 mt-4">
          <div className="text-step1-apply">
            <h4 className="text-step1-apply text-white text-center mb-4">
              {t('About')}
              <br />
              <strong>Rootstack</strong>
            </h4>
            <div className="card bg-transparent m-3">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe title="embed-responsive-item" className="embed-responsive-item" src="https://www.youtube.com/embed/kC2gBAd5UmQ" />
              </div>
              <div className="card-body p-0 mt-4">
                <p className="card-text text-white">{t('With more than 10 years of experience, Rootstack is one of the technology companies with the highest growth projection in Latin America. With over 300 projects completed, our team of experts is ready to begin creating the technology experiences of today and tomorrow.')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
